body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.btn, .btn:focus, .btn:active:focus, .btn.active:focus{
  outline:none!important;
  box-shadow:none!important;
}

.shadow{
  box-shadow: 0 0 30px rgba(0,0,0,1) !important;
}

.avatarNoRound .MuiAvatar-root{
  border-radius: 0.3rem !important;
}

/*
.rdt_TableHeadRow{
  background-color: #212529 !important;
  color: white !important;
  text-align: center;
  margin: auto;
}
*/

/*
.card{
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}
*/

