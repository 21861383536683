tbody tr:hover{
    background-color: antiquewhite;
}
.clickable:hover, .paginatorButton:hover {
    cursor:pointer;
}

td {
    font-size: 0.9rem !important;
}

th{
    font-size: 0.8rem !important;
    padding: 0;
    user-select: none;
    text-align: center;
}

.expandableRow, .expandableRow:hover{
    background-color: #eee !important;
}

.statusLine{
    width: 3px !important;
    padding: 0 !important;
}

table th:first-child{
    border-radius: 0.3rem 0 0 0 ;
}
.results{
    border-radius: 0.3rem 0.3rem 0.3rem 0.3rem !important;
}

.even{
    background-color: white !important;
}

.odd{
    background-color: #f2f2f2 !important;
}

table th:last-child{
    border-radius: 0 0.3rem 0 0;
}

.table thead th, .vAlign {
    vertical-align: middle !important;
}

iframe{
    overflow: hidden !important;
    border: 2px solid #a2a2a2 !important;
}
/*
@media screen and (min-width: 1200px) {
    .table>:not(caption)>*>* {
        padding: 0.3rem !important;
        font-size: 0.8rem !important;
    }
}

@media screen and (max-width: 1200px) {
    .table>:not(caption)>*>* {
        padding: 0.2rem !important;
        font-size: 0.7rem !important;
    }
}

@media screen and (max-width: 995px) {
    .table>:not(caption)>*>* {
        padding: 0.1rem !important;
        font-size: 0.7rem !important;
    }
}*/