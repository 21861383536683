.DateTimePickerError .MuiOutlinedInput-notchedOutline {
    border: 1px solid #d32f2e;
}

.DateTimePickerError label.MuiInputLabel-formControl {
    color: #d32f2e;
}

.DiapasonsDateTimePicker .MuiInputBase-root {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.DiapasonsDateTimePicker .MuiInputLabel-formControl {
    margin-left: 0.5rem;
}