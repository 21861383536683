.navbar .navlink{
    color: white;
    border-bottom: 2px solid #09b5c700;
}
.navbar .navlink:hover{
    color: rgba(9, 181, 199, 0.45);
    border-bottom: 2px solid rgba(9, 181, 199, 0.45);
}
.navbar .navlink.active{
    color: #09b5c7;
    border-bottom: 2px solid #09b5c7;
}