.clickable:hover{
    cursor:pointer;
}

li:hover{
    background-color: antiquewhite !important;
}

.unsavedLi{
    background-color: #0c63e478 !important;
}

.unsavedLi:hover{
    background-color: #0c63e4ba !important;
}

.whiteLi{
    background-color: white !important;
}

.lightGrayLi{
    background-color: lightgray !important;
}
.SuperTransferList .arrow{
    cursor: pointer;
    color: #dbdbdb;
}

.SuperTransferList .arrow:hover{
    cursor: pointer;
    color: #212529;
}

.sideList{
    min-height: 10rem;
    overflow-y: auto;
}

.SuperTransferList .li{
    max-width: 100%;
}

.SuperTransferList .li:hover{
    background-color: antiquewhite;
}

.SuperTransferList .odd{
    background-color: #f2f2f2;
}

.SuperTransferList .even{
    background-color: #f8f8f8;
}