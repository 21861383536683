.FilterType button:hover{
    background-color: #f2f2f2 !important;
}

.FilterType .btnR_c:hover, .FilterType .btnR:hover{
    background-color: rgba(255, 99, 71, 0.5) !important;
    border-color: #ff6347 !important;
}

.FilterType .btnB_c:hover, .FilterType .btnB:hover{
    background-color: rgba(112, 159, 220, 0.5) !important;
    border-color: #709fdc !important;
}

.FilterType .btnY_c:hover, .FilterType .btnY:hover{
    background-color: rgba(255, 144, 0, 0.5) !important;
    border-color: #ff9000 !important;
}

.FilterType .btnGL_c:hover, .FilterType .btnGL:hover{
    background-color: rgba(107, 204, 126, 0.5) !important;
    border-color: #6bcc7e !important;
}

.FilterType .btnG_c:hover, .FilterType .btnG:hover{
    background-color: rgba(50, 170, 72, 0.5) !important;
    border-color: #32aa48 !important;
}
/*--------------*/
.FilterType .btnR_c{
    border-color: #ff6347 !important;
    background-color: #ff6347 !important;
    color: white !important;
}

.FilterType .btnB_c{
    border-color: #709fdc !important;
    background-color: #709fdc !important;
    color: white !important;
}

.FilterType .btnY_c{
    border-color: #ff9000 !important;
    background-color: #ff9000 !important;
    color: white !important;
}

.FilterType .btnGL_c{
    border-color: #6bcc7e !important;
    background-color: #6bcc7e !important;
    color: white !important;
}

.FilterType .btnG_c{
    border-color: #32aa48 !important;
    background-color: #32aa48 !important;
    color: white !important;
}
/*--------------*/
.FilterType .btnR{
    border-color: #ff6347 !important;
    color: black !important;
}

.FilterType .btnB{
    border-color: #709fdc !important;
    color: black !important;
}

.FilterType .btnY{
    border-color: #ff9000 !important;
    color: black !important;
}

.FilterType .btnGL{
    border-color: #6bcc7e !important;
    color: black !important;
}

.FilterType .btnG{
    border-color: #32aa48 !important;
    color: black !important;
}